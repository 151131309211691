<template>
  <div class="">
    <router-view :user="user" />
  </div>
</template>

<script>
// import Sidebar from "./Sidebar";

export default {
  name: "LayoutFluidNoHeaderFooter",
  props: {
    user: Object,
  },
  components: {
    // Sidebar
  },
};
</script>
